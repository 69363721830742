import type { Icon } from "@/models/app/base/appIcon";
import type { InjectionKey, Ref } from "vue";

export type PopoverProviderProperties = { name: PopoverName };

export type PopoverProperties = PopoverProviderProperties & {
  title?: string;
  label?: string;
  icon?: Icon;
  position?: PopoverPosition;
  isEmbedded?: boolean;
};

export enum PopoverName {
  INPUT = "Input",
  PROJECTS = "Projects",
  NOTIFICATIONS = "Notifications",
  SEARCH = "Search",
}

export enum PopoverPosition {
  AUTO = "auto",
  TOP = "top",
  RIGHT = "right",
  BOTTOM = "bottom",
  LEFT = "left",
}

export type PopoverProviderProvide = Ref<HTMLDivElement | null>;

export type PopoverInject = PopoverProviderProvide;

export const popoverDefaults = { position: PopoverPosition.AUTO };

export const popoverInjectionKey = Symbol() as InjectionKey<PopoverProviderProvide>;
