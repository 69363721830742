import { catchRoute, errorRoute, identityRoute, projectRoute, rootRoute } from "@/router/routes";
import { useTitle } from "@vueuse/core";
import type { Ref } from "vue";
import { createRouter, createWebHistory, type RouteLocationNormalized } from "vue-router";

export const router = createRouter({
  routes: [rootRoute, projectRoute, identityRoute, errorRoute, catchRoute],
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: (_to, _from, savedPosition) => savedPosition ?? { top: 0 },
});

router.afterEach(
  (to: RouteLocationNormalized): Ref<string | null | undefined> =>
    useTitle(`${to.meta.title ?? "Личный кабинет"} – Midni`),
);
