import type { ProjectResponse } from "@/api/dto/response/project/ProjectResponse";
import UserDatabase, { ProjectsStore } from "@/repositories/core/UserDatabase";
import { ProjectEntity } from "@/repositories/entities/ProjectEntity";

export default class ProjectsRepository extends UserDatabase {
  public static getAll = async (): Promise<ReadonlyArray<ProjectEntity>> =>
    (await this.getDatabase()).getAll(ProjectsStore.NAME);

  public static put = async (project: ProjectResponse): Promise<string> =>
    (await this.getDatabase()).put(ProjectsStore.NAME, new ProjectEntity(project));

  public static getFromIndex = async (key: string): Promise<ProjectEntity | undefined> =>
    (await this.getDatabase()).getFromIndex(ProjectsStore.NAME, ProjectsStore.INDEX_PATH, key);
}
