<script lang="ts" setup></script>

<template>
  <main class="layout">
    <slot />
  </main>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/decor";

.layout {
  height: decor.$size-wide;
}
</style>
