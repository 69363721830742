<script lang="ts" setup></script>

<template>
  <span class="app-chip">
    <slot />
  </span>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/fonts";

.app-chip {
  font-weight: fonts.$weight-x-bold;
  display: inline-flex;
  cursor: pointer;
  border-bottom: 1px dashed;

  &:hover {
    border-bottom: 1px solid;
  }
}
</style>
