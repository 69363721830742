import type { ProjectEntity } from "@/repositories/entities/ProjectEntity";

export class ProjectResponse {
  id: string;
  name: string;
  path: string;
  image?: string;

  constructor(entity: ProjectEntity) {
    this.id = entity.id;
    this.name = entity.name;
    this.path = entity.path;
    this.image = entity.image;
  }
}
