export default class ColorUtilities {
  /**
   * Получение псевдослучайного цвета в формате HEX из любой строки
   *
   * @param string - любая строка
   * @param lightness - яркость
   * @returns {string} цвет в формате HEX
   */
  public static toHex(string: string, lightness: number = -48): string {
    const hash = (word: string): number => {
      let hash = 0;

      for (let i = 0; i < word.length; i++) {
        hash = word.charCodeAt(i) + ((hash << 5) - hash);
      }

      return hash;
    };

    const shade = (color: string, percent: number) => {
      const num = parseInt(color, 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        G = ((num >> 8) & 0x00ff) + amt,
        B = (num & 0x0000ff) + amt;

      return (
        0x1000000 +
        (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
        (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
        (B < 255 ? (B < 1 ? 0 : B) : 255)
      )
        .toString(16)
        .slice(1);
    };

    const intToRgba = (number: number) =>
      ((number >> 24) & 0xff).toString(16) +
      ((number >> 16) & 0xff).toString(16) +
      ((number >> 8) & 0xff).toString(16) +
      (number & 0xff).toString(16);

    return `#${shade(intToRgba(hash(string)), lightness)}`;
  }

  /**
   * Получение псевдослучайного цвета в формате HSL из любой строки
   *
   * @param string - любая строка
   * @param saturation - насыщенность
   * @param lightness - яркость
   * @returns {string} цвет в формате HSL
   *
   * @see https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37
   */
  public static toHsl = (string: string, saturation: number, lightness: number): string => {
    let hash = 0;

    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    const hue = hash % 360;

    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };
}
