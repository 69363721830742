<script lang="ts" setup>
import AppNotification from "@/components/base/dialogs/AppNotification.vue";
import { useNotifications } from "@/composables/notifications";
import { TransitionGroupName, TransitionGroupTag } from "@/models/app/base/appTransition";

const notifications = useNotifications();
</script>

<template>
  <TransitionGroup
    :name="TransitionGroupName.LIST"
    :tag="TransitionGroupTag.DIV"
    class="the-notification-provider"
  >
    <AppNotification
      v-for="notification in notifications.shown"
      :key="notification.id"
      :model="notification"
    />
  </TransitionGroup>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/decor";

@include animation.group-list;

$indent-denominator: 4;

.the-notification-provider {
  position: fixed;
  z-index: 3;
  top: decor.$indent-x3;
  right: decor.$indent-x4;
  display: flex;
  flex-direction: column;
  width: decor.$width-x2;
  transform: translate(
    calc(#{decor.$size-x3-negative} / #{$indent-denominator}),
    calc(#{decor.$size-x3} / #{$indent-denominator})
  );
  row-gap: decor.$indent-x2;
}
</style>
