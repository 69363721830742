export default class CookieUtilities {
  public static getCookies = (): Record<string, string> => {
    const cookies: Record<string, string> = {};

    if (!document.cookie) {
      return cookies;
    }

    document.cookie.split(";").forEach((cookie) => {
      const [name, value] = cookie.trim().split("=");

      try {
        cookies[name] = JSON.parse(value);
      } catch {
        cookies[name] = value;
      }
    });

    return cookies;
  };

  public static getCookie = (name: string): string | number | boolean | undefined =>
    this.getCookies()[name];
}
