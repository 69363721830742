import CookieUtilities from "@/utilities/CookieUtilities";
import { createAuth0 } from "@auth0/auth0-vue";
/**
 * skipRedirectCallback установлен как true, чтобы избежать редиректа в корень после авторизации.
 *
 * В версии библиотеки auth0-vue 2.3.0 наблюдается проблема, в результате которой сначала
 * производится редирект на redirect_uri, где выполняется обмен кода на токен, а затем, перед
 * переходом на конечный url, сначала выполняется буквально на секунду редирект в корень (/).
 *
 * skipRedirectCallback отключает автоматическую маршрутизацию, а на redirect_uri уже вручную
 * вызывается метод handleRedirectCallback, после которого вручную выполняется переадресация.
 */
export const authProvider = createAuth0(
  {
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    authorizationParams: {
      redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
      audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    },
  },
  {
    skipRedirectCallback: true,
    errorPath: import.meta.env.VITE_AUTH0_ERROR_PATH,
  },
);

export const hasAuthCookie = (): boolean => {
  const clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const cookieName = `auth0.${clientId}.is.authenticated`;

  return !!CookieUtilities.getCookie(cookieName);
};
