<script lang="ts" setup>
import AppIcon from "@/components/base/AppIcon.vue";
import { useNotifications } from "@/composables/notifications";
import { Icon } from "@/models/app/base/appIcon";
import type { NotificationProperties } from "@/models/app/base/appNotification";
import ObjectUtilities from "@/utilities/ObjectUtilities";
import { get, isDefined } from "@vueuse/core";
import { computed, ref } from "vue";

const properties = defineProps<NotificationProperties>();

const notifications = useNotifications();

const isHovered = ref<boolean>(false);

const messages = computed<string | ReadonlyArray<string> | undefined>(() => {
  const messages: string | ReadonlyArray<string> | undefined = ObjectUtilities.deepClone(
    properties.model.messages,
  );

  if (!Array.isArray(messages)) return messages;
  if (messages.length === 1) return messages[0];

  return messages;
});

const isMessageSingle = computed<boolean>(
  () => !isDefined(messages) || !Array.isArray(get(messages)) || get(messages).length <= 1,
);

const formatTimestamp = computed<string>(() =>
  new Intl.DateTimeFormat("ru-RU", {
    hour: "numeric",
    minute: "numeric",
  })
    .format(new Date(properties.model.id!))
    .replace(",", ""),
);
</script>

<template>
  <div
    :class="[`app-notification--theme-${model.theme}`]"
    class="app-notification"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <!-- заголовок -->
<!--    <div class="notification-header">
      <AppIcon :name="model.icon ?? Icon.BELL" />
      <span class="notification-header__title">{{ model.title }}</span>
      <AppIcon
        v-if="model.isShown && isHovered"
        :name="Icon.X"
        @click="notifications.hide(model)"
      />
      <small v-if="!model.isShown">{{ formatTimestamp }}</small>
    </div>-->
    <!-- одно сообщение -->
    <span v-if="isMessageSingle">
      {{ messages }}
    </span>
    <!-- несколько сообщений -->
    <ul
      v-if="!isMessageSingle"
      class="notification-messages"
    >
      <li
        v-for="message in messages"
        :key="message"
        class="notification-messages__item"
      >
        {{ message }}
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/palette";

.app-notification {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: decor.$size-wide;
  padding: decor.$indent-x3 decor.$indent-x4;
  transition:
    background-color animation.$speed-regular animation.$mode-bezier-out,
    color animation.$speed-regular animation.$mode-bezier-out;
  word-wrap: break-word;
  border-radius: decor.$radius-x1;
  row-gap: decor.$indent-x1;

  &--theme-regular {
    color: palette.$on-regular;
    background-color: palette.$regular;
  }

  &--theme-primary {
    color: palette.$on-primary;
    background-color: palette.$primary;
  }

  &--theme-success {
    color: palette.$on-success;
    background-color: palette.$success;
  }

  &--theme-info {
    color: palette.$on-info;
    background-color: palette.$info;
  }

  &--theme-warning {
    color: palette.$on-warning;
    background-color: palette.$warning;
  }

  &--theme-danger {
    color: palette.$on-danger;
    background-color: palette.$danger;
  }
}

.notification-header {
  display: flex;
  align-items: center;

  &__title {
    font-weight: fonts.$weight-x-bold;
    flex-grow: 1;
    margin-left: decor.$indent-x2;
  }
}

.notification-messages {
  &__item {
    position: relative;
    padding-left: decor.$indent-x4;
    list-style: none;

    &::before {
      position: absolute;
      left: decor.$indent-fixer;
      content: "○";
    }
  }
}
</style>
