import type { UserResponse } from "@/api/dto/response/user/UserResponse";

export class UserEntity {
  id: string;
  username: string;
  email: string;
  phone: string;
  lastUsed: number;

  constructor(response: UserResponse, lastUsed: number = +new Date()) {
    this.id = response.id;
    this.username = response.username;
    this.email = response.email;
    this.phone = response.phone;
    this.lastUsed = lastUsed;
  }
}
