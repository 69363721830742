import { ulid } from "ulid";

export default class StringUtilities {
  /**
   * Плюрализация количественно измеряемых значений
   *
   * @param number {number} число, для которого требуется вернуть форму
   * @param strings {ReadonlyArray<string>} массив форм (одно, два, несколько)
   * @returns {string} вычисленная форма
   *
   * @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html?id=l10n/pluralforms
   */
  public static getCountForm = (number: number, ...strings: ReadonlyArray<string>): string =>
    strings[
      number % 10 == 1 && number % 100 != 11
        ? 0
        : number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)
          ? 1
          : 2
    ];

  /**
   * Создание аббревиатуры из строки. Пример: "Тестовый пример текста" -> "ТПТ".
   *
   * @param string {string} исходная строка
   * @param limit {number} максимальное количество символов
   * @returns {string} извлечённая аббревиатура
   */
  public static getAbbreviation = (string: string, limit?: number): string | undefined =>
    string
      .match(/\p{Alpha}+/gu)
      ?.reduce(
        (previous, next) =>
          previous + (+next === 0 || parseInt(next) ? parseInt(next) : next[0] || ""),
        "",
      )
      ?.substring(0, limit)
      .toUpperCase() ?? "";

  /**
   * Приводит английскую раскладку к кириллической. Пример: "ghbdtn" -> "привет".
   *
   * @param string {string} исходная строка
   * @returns {string} изменённая раскладка
   */
  public static convertKeyboardLayout = (string: string): string => {
    const ruLibrary = "йцукенгшщзхъфывапролджячсмитьбюЙЦУКЕНГШЩЗХЪФЫВАПРОЛДЖЯЧСМИТЬБЮ";
    const enLibrary = "qwertyuiop[]asdfghjkl;zxcvbnm,.QWERTYUIOP{}ASDFGHJKL:ZXCVBNM<>";

    if (Array.from(string).some((letter: string): boolean => ruLibrary.includes(letter))) {
      return string;
    }

    return Array.from(
      string,
      (letter: string): string => ruLibrary[enLibrary.indexOf(letter)],
    ).join("");
  };

  /**
   * Генерация уникального ID по спецификации ULID (пасхальный: 00mybpytt02ne7krr7fwtbmg1p)
   *
   * @param config {object} параметры выходного ULID
   * @param config.isShort {boolean} минус 2 начальных символа "01" (хватит до 07.08.2039)
   * @param config.randomLength {number} длина строки случайной части (16 по умолчанию)
   * @returns {string} ULID в форматах от "mybpytt0" до "01mybpytt02ne7krr7fwtbmg1p"
   *
   * @see https://github.com/ulid/spec
   */
  public static getUlid = (config?: { isShort?: boolean; randomLength?: number }): string => {
    const original: string = ulid();
    const lowercase: string = original.toLowerCase();

    if (!config) {
      return lowercase;
    }

    const timestampLength = 10;
    const randomLength = 16;

    const timestamp = lowercase.substring(config.isShort ? 2 : 0, timestampLength);
    const random = lowercase.substring(
      timestampLength,
      timestampLength + (config.randomLength ?? randomLength),
    );

    return timestamp + random;
  };
  /**
   * Проверка строки на соответствие спецификации ULID
   *
   * @param ulid - предполагаемый ULID
   * @return ulid - результат валидации
   */
  public static isUlid = (ulid: string): boolean => {
    const regex = new RegExp("^[0-7][0-9a-hjkmnp-tv-z]{25}$");
    return regex.test(ulid);
  };
}
