<script lang="ts" setup>
import AppTeleport from "@/components/base/AppTeleport.vue";
import { usePopovers } from "@/composables/popovers";
import type {
  PopoverProviderProperties,
  PopoverProviderProvide,
} from "@/models/app/base/appPopover";
import { popoverInjectionKey } from "@/models/app/base/appPopover";
import { TeleportDestination } from "@/models/app/base/appTeleport";
import { type Component, computed, defineAsyncComponent, provide, ref, useAttrs } from "vue";

defineOptions({ inheritAttrs: false });
const properties = defineProps<PopoverProviderProperties>();

const attributes = useAttrs();
const popovers = usePopovers();

const element = ref<HTMLDivElement | null>(null);

const isPopoverShown = computed<boolean>(() => popovers.shown.includes(properties.name));

const popoverComponent = computed<Component>(() =>
  defineAsyncComponent(
    () => import(`./../../../main/dialogs/popovers/Popover${properties.name}.vue`),
  ),
);

provide<PopoverProviderProvide>(popoverInjectionKey, element);
</script>

<template>
  <!-- обёртка -->
  <div
    ref="element"
    :class="attributes.class"
    @click="popovers.toggle(name)"
  >
    <slot />
  </div>
  <!-- поповер -->
  <AppTeleport :destination="TeleportDestination.BODY">
    <Component
      :is="popoverComponent"
      v-if="isPopoverShown"
    />
  </AppTeleport>
</template>

<style lang="scss" scoped></style>
