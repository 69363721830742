<script lang="ts" setup>
import { computed, type CSSProperties } from "vue";

const properties = defineProps<{ label?: string }>();

const css = computed<CSSProperties>(() => ({ display: properties.label ? "flex" : "none" }));
</script>

<template>
  <div
    :data-content="label"
    class="app-tooltip"
  >
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/palette";

$backdrop-filter: blur(24px);

.app-tooltip {
  position: relative;
  z-index: 1;

  &:after {
    position: absolute;
    top: decor.$size-half;
    left: decor.$size-wide;
    display: v-bind("css.display");
    align-items: center;
    width: max-content;
    height: decor.$size-z8;
    padding: 0 decor.$gap-x6;
    content: attr(data-content);
    transform: translate(decor.$gap-x2, decor.$size-half-negative);
    color: palette.$on-background;
    border-radius: decor.$radius-small;
    box-shadow: decor.$shadow-regular, decor.$shadow-dialog;
    backdrop-filter: $backdrop-filter;
  }
}
</style>
