import ProjectsController from "@/api/controller/ProjectsController";
import type { ProjectCreateRequest } from "@/api/dto/request/project/ProjectCreateRequest";
import { ProjectResponse } from "@/api/dto/response/project/ProjectResponse";
import type { WebClientResponse } from "@/plugins/webClient";
import type { ProjectEntity } from "@/repositories/entities/ProjectEntity";
import ProjectsRepository from "@/repositories/ProjectsRepository";
import { router } from "@/router";
import * as Sentry from "@sentry/vue";
import { get, isDefined, set, whenever } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref, watch } from "vue";

export const useProjectsStore = defineStore("projects", () => {
  const projects = ref<Array<ProjectResponse>>([]);
  const activeProject = ref<ProjectResponse | undefined>();

  const readProjects = async (): WebClientResponse<ReadonlyArray<ProjectResponse>> => {
    await getProjects();

    const response = await ProjectsController.readProjects();

    if (isDefined(response.data)) {
      set(projects, get(response.data).data);

      for (const project of get(response.data).data!) {
        await ProjectsRepository.put(project);
      }
    }

    return response;
  };

  const createProject = async (
    request: ProjectCreateRequest,
  ): WebClientResponse<ProjectResponse> => {
    const response = await ProjectsController.createProject(request);

    if (isDefined(response.data)) {
      get(projects).push(get(response.data).data!);

      await ProjectsRepository.put(get(response.data).data!);
    }

    return response;
  };

  const getProjects = async (): Promise<Array<ProjectResponse>> => {
    const entities = await ProjectsRepository.getAll();

    set(
      projects,
      entities.map((entity) => new ProjectResponse(entity)),
    );

    return get(projects);
  };

  const getActiveProject = async (): Promise<ProjectResponse | undefined> => {
    const projectPath = get(router.currentRoute).params.projectPath;

    if (projectPath) {
      const entity = await ProjectsRepository.getFromIndex(String(projectPath));
      set(activeProject, entity && new ProjectResponse(entity));
    } else {
      set(activeProject, await getLastProject());
    }

    return get(activeProject);
  };

  const getLastProject = async (): Promise<ProjectResponse | undefined> => {
    const entities = await ProjectsRepository.getAll();

    if (!entities.length) {
      return;
    }

    const entity = entities.reduce(
      (previous, current): ProjectEntity =>
        current.lastUsed > previous.lastUsed ? current : previous,
    );

    return new ProjectResponse(entity);
  };

  const setActiveProject = async (project: ProjectResponse): Promise<ProjectResponse> => {
    await ProjectsRepository.put(project);

    set(activeProject, project);

    return get(activeProject)!;
  };

  watch(
    () => get(router.currentRoute).params.projectPath,
    async (): Promise<void> => {
      await Promise.all([getProjects(), getActiveProject()]);
      // todo: переписать на что-то менее костыльное для обновления даты lastUsed
      await setActiveProject(get(activeProject)!);
    },
  );

  whenever(activeProject, (activeProject: ProjectResponse) =>
    Sentry.setContext("Active Project", {
      id: get(activeProject).id,
      name: get(activeProject).name,
      path: get(activeProject).path,
    }),
  );

  return {
    projects,
    activeProject,
    readProjects,
    createProject,
    getActiveProject,
  };
});
