<script lang="ts" setup>
import { demoProjectResponse } from "@/assets/mocks/demoProjectResponse";
import AppAlert from "@/components/base/AppAlert.vue";
import AppChip from "@/components/base/AppChip.vue";
import { useModals } from "@/composables/modals";
import { usePopovers } from "@/composables/popovers";
import { AlertTheme } from "@/models/app/base/appAlert";
import { ModalName } from "@/models/app/base/appModal";
import { PopoverName } from "@/models/app/base/appPopover";
import { useProjectsStore } from "@/stores/projectsStore";
import { computed } from "vue";

const modals = useModals();
const popovers = usePopovers();
const projectsStore = useProjectsStore();

const isShown = computed<boolean>(() => projectsStore.activeProject?.id === demoProjectResponse.id);

const hasOwnProject = computed<boolean>(() => projectsStore.projects.length > 1);
</script>

<template>
  <AppAlert
    v-if="isShown"
    :theme="AlertTheme.PRIMARY"
    class="alert-layout-level"
  >
    <!-- если есть собственные проекты -->
    <template v-if="hasOwnProject">
      Включён демонстрационный режим. Для отображения реальных данных
      <AppChip @click="popovers.show(PopoverName.PROJECTS)">выберите проект</AppChip>
      из списка ранее добавленных.
    </template>
    <!-- если нет ещё ни одного проекта -->
    <template v-if="!hasOwnProject">
      <AppChip @click="modals.show(ModalName.PROJECT)">Добавьте проект</AppChip>
      для получения данных ваших собственных маркетплейсов. Сейчас включен демонстрационный режим.
    </template>
  </AppAlert>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/decor";

.alert-layout-level {
  margin-bottom: decor.$gap-x6;
}
</style>
