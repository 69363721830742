import type { UserResponse } from "@/api/dto/response/user/UserResponse";
import AppDatabase, { UsersStore } from "@/repositories/core/AppDatabase";
import { UserEntity } from "@/repositories/entities/UserEntity";

export default class UsersRepository extends AppDatabase {
  public static getAll = async (): Promise<ReadonlyArray<UserEntity>> =>
    (await this.database).getAll(UsersStore.NAME);

  public static put = async (user: UserResponse): Promise<string> =>
    (await this.database).put(UsersStore.NAME, new UserEntity(user));
}
