import { useModals } from "@/composables/modals";
import { usePopovers } from "@/composables/popovers";
import { ModalName } from "@/models/app/base/appModal";
import { PopoverName } from "@/models/app/base/appPopover";
import BrowserUtilities from "@/utilities/BrowserUtilities";
import * as Sentry from "@sentry/vue";

export enum UrlParameter {
  STATE = "s",
  SEARCH = "q",
  FILTER = "f",
}

export enum StateSubject {
  MODAL = "m",
  POPOVER = "p",
}

type StateItem = ModalName | PopoverName;

export const useApp = (): {
  setState: (subject?: StateSubject, state?: StateItem, isRemoved?: boolean) => void;
  loadState: () => void;
} => {
  const url = new URL(window.location.href);
  const urlParams = url.searchParams.get(UrlParameter.STATE);
  const urlParamsExploded = urlParams?.split("-") ?? [];

  /* ?c=mhel.tuse.fnam-mpro.fuse */
  const setState = (subject?: StateSubject, state?: StateItem, isRemoved?: boolean): void => {
    if (!subject) {
      url.searchParams.delete(UrlParameter.STATE);
      return history.replaceState({}, "", url);
    }

    const stateItemShort = subject + state?.substring(0, 3).toLowerCase();

    if (!isRemoved && urlParamsExploded.includes(stateItemShort)) {
      return;
    }

    if (
      !state ||
      (isRemoved && !urlParamsExploded.length) ||
      (isRemoved && !urlParamsExploded.includes(stateItemShort)) ||
      (isRemoved && urlParamsExploded.includes(stateItemShort) && urlParamsExploded.length === 1)
    ) {
      return setState();
    }

    if (!isRemoved) {
      urlParamsExploded.push(stateItemShort);
    } else {
      urlParamsExploded.splice(urlParamsExploded.indexOf(stateItemShort), 1);
    }

    url.searchParams.set(UrlParameter.STATE, urlParamsExploded.join("-"));

    return history.replaceState({ [UrlParameter.STATE]: urlParamsExploded }, "", url);
  };

  const loadState = (): void => {
    if (!urlParams || !urlParamsExploded.length) {
      return;
    }

    urlParamsExploded.forEach(async (stateItemShort: string, i: number): Promise<void> => {
      // todo: разобраться, почему модальные окна загружаются с задержкой
      await BrowserUtilities.wait((i + 1) * 1000);

      switch (stateItemShort.substring(0, 1)) {
        case StateSubject.POPOVER: {
          const name: PopoverName | undefined = Object.values(PopoverName).find(
            (name: PopoverName): boolean =>
              name.substring(0, 3).toLowerCase() === stateItemShort.substring(1),
          );
          return void usePopovers().show(<PopoverName>name);
        }
        case StateSubject.MODAL: {
          const name: ModalName | undefined = Object.values(ModalName).find(
            (name: ModalName): boolean =>
              name.substring(0, 3).toLowerCase() === stateItemShort.substring(1),
          );
          return void useModals().show(<ModalName>name);
        }
        default:
          Sentry.captureMessage("useApp.loadState.switch.default", "warning");
      }
    });
  };

  return { setState, loadState };
};

useApp().loadState();
