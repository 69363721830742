import { type ResponseMessage } from "@/api/dto/response/core/ResponseMessage";
import type { ResponseWrapper } from "@/api/dto/response/core/ResponseWrapper";
import { authProvider } from "@/plugins/authProvider";
import { router } from "@/router";
import { useProjectsStore } from "@/stores/projectsStore";
import {
  type AfterFetchContext,
  type BeforeFetchContext,
  type OnFetchErrorContext,
  type UseFetchReturn,
} from "@vueuse/core";
import { createFetch, get } from "@vueuse/core";

export type WebClientResponse<T> = Promise<
  UseFetchReturn<ResponseWrapper<T>> & PromiseLike<UseFetchReturn<ResponseWrapper<T>>>
>;

const getRequestHeaders = async (): Promise<Record<string, string>> => {
  const headers: Record<string, string> = {
    Authorization: `Bearer ${await authProvider.getAccessTokenSilently()}`,
  };

  if (get(router.currentRoute).params.projectPath) {
    headers["Midni-Project"] = (await useProjectsStore().getActiveProject())?.id!;
  }

  return headers;
};

const beforeFetch = async (context: BeforeFetchContext): Promise<BeforeFetchContext> => {
  context.options.headers = {
    ...context.options.headers,
    ...(await getRequestHeaders()),
  };

  return context;
};

const afterFetch = (context: AfterFetchContext): AfterFetchContext => {
  if (!context.data.isSuccessful) {
    throw new Error(context.data);
  }
  // todo: добавить механизм кэширования ответов в indexedDB
  return context;
};

const onFetchError = (context: OnFetchErrorContext): OnFetchErrorContext => {
  context.error =
    context.data ??
    ({
      isSuccessful: false,
      isRepeatable: false,
      response: [
        {
          code: "LOCAL_UNKNOWN_EXCEPTION",
          message: "Нет связи с сервером. Проверьте соединение с сетью и повторите попытку.",
          isRepeatable: false,
        },
      ],
    } as ResponseWrapper);

  return context;
};

export const webClient = createFetch({
  baseUrl: import.meta.env.VITE_RESOURCE_SERVER_URL,
  options: {
    beforeFetch,
    afterFetch,
    onFetchError,
    refetch: false,
    timeout: import.meta.env.VITE_HTTP_REQUEST_TIMEOUT,
  },
  fetchOptions: { mode: "cors" },
});
