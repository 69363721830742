export type AvatarProperties = {
  label?: string;
  image?: string;
  color?: string;
  size?: AvatarSize;
};

export enum AvatarSize {
  X_SMALL = "x-small",
  SMALL = "small",
  REGULAR = "regular",
  LARGE = "large",
  X_LARGE = "x-large",
}

export const avatarDefaults: Pick<AvatarProperties, "color" | "size"> = {
  color: "--color-primary",
  size: AvatarSize.REGULAR,
};
