import App from "@/App.vue";
import "@/assets/scss/main.scss";
import { useApp } from "@/composables/useApp";
import { useWorkbox } from "@/composables/workbox";
import { authProvider } from "@/plugins/authProvider";
import { errorHandler } from "@/plugins/errorHandler";
import { router } from "@/router";
import { getCurrentHub } from "@sentry/browser";
import { createPinia } from "pinia";
import { createApp } from "vue";

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(authProvider);
app.use(useWorkbox);
app.use(useApp);

getCurrentHub().bindClient(errorHandler);

app.mount("#app");
