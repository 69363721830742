import { useNotifications } from "@/composables/notifications";
import type { Notification } from "@/models/app/base/appNotification";
import { NotificationDuration, NotificationTheme } from "@/models/app/base/appNotification";
import { set, whenever } from "@vueuse/core";
import { useRegisterSW } from "virtual:pwa-register/vue";

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();
const notifications = useNotifications();

export const useWorkbox = (): void => {
  const close = async (): Promise<void> => {
    set(offlineReady, false);
    set(needRefresh, false);
  };

  whenever(offlineReady, async (): Promise<void> => await close());

  whenever(
    needRefresh,
    (): Notification =>
      notifications.show({
        theme: NotificationTheme.PRIMARY,
        messages: "Доступно обновление приложения",
        duration: NotificationDuration.UNLIMITED,
        actions: [
          {
            title: "Обновить",
            callback: updateServiceWorker,
          },
          {
            title: "Отложить",
            callback: close,
          },
        ],
      }),
  );
};
