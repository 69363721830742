<script lang="ts" setup>
import AppButton from "@/components/base/AppButton.vue";
import AppShortcut from "@/components/base/AppShortcut.vue";
import AppPopoverProvider from "@/components/base/dialogs/popover/AppPopoverProvider.vue";
import { useModals } from "@/composables/modals";
import { usePopovers } from "@/composables/popovers";
import { ButtonLayout, ButtonMode, ButtonSize } from "@/models/app/base/appButton";
import { Icon } from "@/models/app/base/appIcon";
import { ModalName } from "@/models/app/base/appModal";
import { PopoverName } from "@/models/app/base/appPopover";
import { useMagicKeys, whenever } from "@vueuse/core";
import { h } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const modals = useModals();
const popovers = usePopovers();
const { current } = useMagicKeys();

whenever(
  () => current.has("alt") && (current.has("f1") || current.has("h") || current.has("р")),
  () => modals.show(ModalName.HELP),
);

whenever(
  () => current.has("alt") && (current.has("s") || current.has("ы")),
  () => modals.show(ModalName.SETTINGS),
);

whenever(
  () => current.has("alt") && (current.has("k") || current.has("л")),
  () => popovers.show(PopoverName.SEARCH),
);

whenever(
  () => current.has("alt") && (current.has("n") || current.has("т")),
  () => popovers.show(PopoverName.NOTIFICATIONS),
);
</script>

<template>
  <header class="the-navbar">
    <!-- заголовок страницы -->
    <div class="the-navbar-navigator">
      <span class="the-navbar-navigator__title">{{ route.meta.title }}</span>
      <span class="the-navbar-navigator__label">Обновлено только что</span>
    </div>
    <!-- поиск -->
    <AppPopoverProvider :name="PopoverName.SEARCH">
      <AppButton
        :layout="ButtonLayout.FLAT"
        :leading="Icon.TERMINAL_2"
        :mode="ButtonMode.WIDE"
        :size="ButtonSize.LARGE"
        :trailing="h(AppShortcut, (): string => 'K')"
        class="the-navbar__search"
      >
        {{ "Поиск и команды" }}
      </AppButton>
    </AppPopoverProvider>
    <!-- набор иконок -->
    <div class="the-navbar__icons">
      <!-- справка и поддержка -->
      <AppButton
        :layout="ButtonLayout.FLAT"
        :leading="Icon.HOME_QUESTION"
        :mode="ButtonMode.EQUILATERAL"
        class="the-navbar__search"
        @click="modals.show(ModalName.HELP)"
      />
      <!-- уведомления -->
      <AppPopoverProvider :name="PopoverName.NOTIFICATIONS">
        <AppButton
          :layout="ButtonLayout.FLAT"
          :leading="Icon.MESSAGE_2"
          :mode="ButtonMode.EQUILATERAL"
          class="the-navbar__search"
        />
      </AppPopoverProvider>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/md";
@use "@/assets/scss/fragments/palette";

.the-navbar {
  display: grid;
  align-items: center;
  height: decor.$size-wide;
  padding: decor.$gap-x7 decor.$gap-x8 decor.$gap-x4;
  color: palette.$on-surface;
  column-gap: decor.$gap-x8;

  @include md.query(md.$range-min, md.$breakpoint-tablet-regular) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include md.query(md.$range-max, md.$breakpoint-tablet-regular) {
    grid-template-columns: 2fr 1.5fr 2fr;
  }

  @include md.query(md.$range-max, md.$breakpoint-mobile-large) {
    :deep(.the-project-selector) {
      padding: 0;
    }

    grid-template-columns: decor.$size-z9 auto auto;
    column-gap: decor.$gap-x4;
  }

  &__search {
    width: decor.$size-wide;
    padding-right: decor.$gap-x4 !important;

    @include md.query(md.$range-max, md.$breakpoint-tablet-regular) {
      padding: 0 decor.$gap-x8 !important;
    }

    :last-child {
      margin-left: auto;
    }
  }

  &__icons {
    display: flex;
    justify-content: flex-end;
    column-gap: decor.$gap-x4;
  }
}

.the-navbar-navigator {
  display: flex;
  flex-direction: column;
  row-gap: decor.$gap-x1;

  &__title {
    font-weight: fonts.$weight-x-bold;
    @include fonts.truncate;
  }

  &__label {
    font-size: fonts.$size-small;
    font-weight: fonts.$weight-bold;
    color: palette.$on-regular-accent;
    @include fonts.truncate;
  }
}
</style>
