import type { ProjectCreateRequest } from "@/api/dto/request/project/ProjectCreateRequest";
import type { ProjectResponse } from "@/api/dto/response/project/ProjectResponse";
import { webClient, type WebClientResponse } from "@/plugins/webClient";

export default class ProjectsController {
  public static readProjects = async (): WebClientResponse<ReadonlyArray<ProjectResponse>> =>
    await webClient("v0/projects").get().json();

  public static createProject = async (
    request: ProjectCreateRequest,
  ): WebClientResponse<ProjectResponse> => await webClient("v0/projects").post(request).json();
}
