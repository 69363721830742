<script lang="ts" setup>
import { type ButtonWaveProperties } from "@/models/app/base/appButton";
import { get, whenever } from "@vueuse/core";
import { onMounted, ref } from "vue";

const properties = defineProps<ButtonWaveProperties>();

const classes = ref<Array<string>>(["app-button-wave"]);

onMounted(() => setTimeout(() => get(classes).push("app-button-wave--pressed")));

whenever(
  () => properties.model.isReleased,
  () => get(classes).push("app-button-wave--released"),
);
</script>

<template>
  <span
    :class="classes"
    :style="{ top: `${model.position.y}px`, left: `${model.position.x}px` }"
  ></span>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/decor";

$background-color: rgba(43, 45, 66, 0.1);

.app-button-wave {
  position: absolute;
  overflow: hidden;
  width: calc(#{decor.$size-wide} * 3);
  transition: transform 7.5s animation.$mode-bezier-out;
  transform: translate(#{decor.$size-half-negative}, #{decor.$size-half-negative}) scale(0);
  pointer-events: none;
  border-radius: decor.$radius-maximum;
  background-color: $background-color;
  aspect-ratio: 1;

  &--pressed {
    transform: translate(#{decor.$size-half-negative}, #{decor.$size-half-negative}) scale(1);
  }

  &--released {
    transition: calc(#{animation.$speed-x-long} * 6) animation.$mode-bezier-out;
    transition-property: opacity, transform;
    opacity: 0;
  }
}
</style>
