<script lang="ts" setup>
import logo from "@/assets/images/logo-dark.svg";
import type { ImageProperties } from "@/models/app/base/appImage";
import { get, useImage } from "@vueuse/core";
import { computed } from "vue";

const properties = defineProps<ImageProperties>();

const image = useImage({ src: properties.source ?? "/logo-dark.svg" });

const source2 = computed<string>(() =>
  !properties.source || get(image.error) || image.isLoading ? logo : properties.source,
);
</script>

<template>
  <img
    :src="source"
    alt="image"
    class="app-image"
    referrerpolicy="no-referrer"
  />
</template>

<style lang="scss" scoped>
.app-image {
  object-fit: cover;
}
</style>
