<script lang="ts" setup>
import AppAvatar from "@/components/base/AppAvatar.vue";
import AppIcon from "@/components/base/AppIcon.vue";
import AppPopoverProvider from "@/components/base/dialogs/popover/AppPopoverProvider.vue";
import { Icon, IconSize } from "@/models/app/base/appIcon";
import { PopoverName } from "@/models/app/base/appPopover";
import { useProjectsStore } from "@/stores/projectsStore";
import { useSettingsStore } from "@/stores/settingsStore";
import { useUsersStore } from "@/stores/usersStore";

const projectsStore = useProjectsStore();
const settingsStore = useSettingsStore();
const usersStore = useUsersStore();
</script>

<template>
  <AppPopoverProvider :name="PopoverName.PROJECTS">
    <div class="the-project-selector">
      <AppAvatar
        :color="projectsStore.activeProject?.id"
        :image="projectsStore.activeProject?.image"
        :label="projectsStore.activeProject?.name"
      />
      <template v-if="!settingsStore.sidebar.isCollapsed">
        <div class="profile-data">
          <span class="profile-data__name">
            {{ projectsStore.activeProject?.name }}
          </span>
          <span class="profile-data__email">
            {{ usersStore.activeUser?.email }}
          </span>
        </div>
        <AppIcon
          :name="Icon.SELECTOR"
          :size="IconSize.SMALL"
          class="the-project-selector__icon"
        />
      </template>
    </div>
  </AppPopoverProvider>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/palette";

.the-project-selector {
  display: flex;
  align-items: center;
  padding: decor.$gap-x8;
  cursor: pointer;
  column-gap: decor.$gap-x5;

  &:hover {
    .profile-data__email {
      color: palette.$on-sidebar;
    }
  }

  &:active {
    .profile-data__name,
    .profile-data__email {
      color: palette.$on-sidebar-dark;
    }
  }

  &__icon {
    color: palette.$on-sidebar-light;
  }

  &:hover & {
    &__icon {
      color: palette.$on-sidebar;
    }
  }

  &:active & {
    &__icon {
      color: palette.$on-sidebar-dark;
    }
  }
}

.profile-data {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex-grow: 1;
  row-gap: decor.$gap-x1;

  &__name {
    font-weight: fonts.$weight-x-bold;
    @include fonts.truncate;
  }

  &__email {
    font-size: fonts.$size-small;
    font-weight: fonts.$weight-bold;
    transition: color animation.$speed-regular animation.$mode-bezier-out;
    color: palette.$on-sidebar-light;
    @include fonts.truncate;
  }
}
</style>
