import type { ProjectResponse } from "@/api/dto/response/project/ProjectResponse";
import { IdentificationMethod } from "@/constants/IdentificationMethod";
import RouteName from "@/constants/RouteName";
import BlankLayout from "@/layouts/BlankLayout.vue";
import ProjectLayout from "@/layouts/ProjectLayout.vue";
import { authProvider, hasAuthCookie } from "@/plugins/authProvider";
import { router } from "@/router/index";
import { useProjectsStore } from "@/stores/projectsStore";
import { useUsersStore } from "@/stores/usersStore";
import { get } from "@vueuse/core";
import { h } from "vue";
import type { RouteLocationNormalized, RouteLocationRaw, RouteRecordRaw } from "vue-router";
import { RouterView } from "vue-router";

const verifyActiveUser = async (): Promise<RouteLocationRaw | void> => {
  const usersStore = useUsersStore();
  const activeUser = usersStore.activeUser ?? (await usersStore.getActiveUser());

  if (activeUser) {
    return;
  }

  return { name: RouteName.IDENTITIES };
};

const getActiveProject = async (): Promise<ProjectResponse | undefined> => {
  const projectStore = useProjectsStore();

  return projectStore.activeProject ?? (await projectStore.getActiveProject());
};

const defineProjectPath = async (): Promise<RouteLocationRaw> => {
  const activeProject = await getActiveProject();

  return activeProject
    ? {
        name: RouteName.PROJECT,
        params: { projectPath: activeProject.path },
      }
    : { name: RouteName.ERROR };
};

const verifyProjectPath = async (to: RouteLocationNormalized): Promise<RouteLocationRaw | void> => {
  const activeProject = await getActiveProject();
  const projectPath = to.params.projectPath;

  if (activeProject && projectPath) {
    return;
  }

  /* todo: убедиться в наличии у активного пользователя доступа к проекту */

  return { name: RouteName.ERROR };
};

const onSessionChecked = async (): Promise<void> => {
  if (get(authProvider.isAuthenticated)) {
    return;
  }

  /* todo: убедиться в соответствии активного пользователя с авторизованным */

  await router.push({ name: RouteName.IDENTITIES });
};

const verifyIdentity = (): RouteLocationRaw | void => {
  if ((!get(authProvider.isLoading) && !get(authProvider.isAuthenticated)) || !hasAuthCookie()) {
    return { name: RouteName.IDENTITIES };
  }

  if (!get(authProvider.isLoading) && get(authProvider.isAuthenticated)) {
    return;
  }

  authProvider.checkSession().then(onSessionChecked);
};

export const rootRoute: RouteRecordRaw = {
  path: "/",
  name: RouteName.ROOT,
  strict: true,
  component: () => h(RouterView),
  beforeEnter: [verifyActiveUser, defineProjectPath],
};

export const projectRoute: RouteRecordRaw = {
  path: "/:projectPath",
  name: RouteName.PROJECT,
  strict: true,
  redirect: { name: RouteName.STATISTICS },
  beforeEnter: [verifyActiveUser, verifyProjectPath],
  children: [
    {
      path: "statistics",
      name: RouteName.STATISTICS,
      strict: true,
      redirect: { name: RouteName.STATISTICS_INCOMES },
      children: [
        {
          path: "incomes",
          name: RouteName.STATISTICS_INCOMES,
          strict: true,
          component: () => import("../views/statistics/StatisticsIncomesView.vue"),
          beforeEnter: verifyIdentity,
          meta: {
            title: "Статистика по поставкам",
            layout: ProjectLayout,
          },
        },
        {
          path: "stocks",
          name: RouteName.STATISTICS_STOCKS,
          strict: true,
          component: () => import("../views/statistics/StatisticsStocksView.vue"),
          beforeEnter: verifyIdentity,
          meta: {
            title: "Статистика по складам",
            layout: ProjectLayout,
          },
        },
        {
          path: "orders",
          name: RouteName.STATISTICS_ORDERS,
          strict: true,
          component: () => import("../views/statistics/StatisticsOrdersView.vue"),
          beforeEnter: verifyIdentity,
          meta: {
            title: "Статистика по заказам",
            layout: ProjectLayout,
          },
        },
        {
          path: "sales",
          name: RouteName.STATISTICS_SALES,
          strict: true,
          component: () => import("../views/statistics/StatisticsSalesView.vue"),
          beforeEnter: verifyIdentity,
          meta: {
            title: "Статистика по продажам",
            layout: ProjectLayout,
          },
        },
        {
          path: "realizations",
          name: RouteName.STATISTICS_REALIZATIONS,
          strict: true,
          component: () => import("../views/statistics/StatisticsRealizationsView.vue"),
          beforeEnter: verifyIdentity,
          meta: {
            title: "Статистика по реализации",
            layout: ProjectLayout,
          },
        },
      ],
    },
    {
      path: "components",
      name: RouteName.COMPONENTS,
      strict: true,
      redirect: { name: RouteName.COMPONENTS_BUTTONS },
      children: [
        {
          path: "buttons",
          name: RouteName.COMPONENTS_BUTTONS,
          strict: true,
          component: () => import("../views/components/ComponentsButtonsView.vue"),
          meta: {
            title: "Кнопки",
            layout: ProjectLayout,
          },
        },
        {
          path: "icons",
          name: RouteName.COMPONENTS_ICONS,
          strict: true,
          component: () => import("../views/components/ComponentsIconsView.vue"),
          meta: {
            title: "Иконки",
            layout: ProjectLayout,
          },
        },
        {
          path: "charts",
          name: RouteName.COMPONENTS_CHARTS,
          strict: true,
          component: () => import("../views/components/ComponentsChartsView.vue"),
          meta: {
            title: "Графики",
            layout: ProjectLayout,
          },
        },
      ],
    },
  ],
};

export const identityRoute: RouteRecordRaw = {
  path: "/identities",
  name: RouteName.IDENTITIES,
  strict: true,
  redirect: {
    name: RouteName.IDENTIFICATION_METHOD,
    params: { identificationMethod: IdentificationMethod.CREATE },
  },
  children: [
    {
      path: ":identificationMethod",
      name: RouteName.IDENTIFICATION_METHOD,
      strict: true,
      component: () => import("../views/IdentitiesView.vue"),
      meta: {
        title: "Идентификация",
        layout: BlankLayout,
      },
    },
  ],
};

export const errorRoute: RouteRecordRaw = {
  path: "/error",
  name: RouteName.ERROR,
  strict: true,
  component: () => import("../views/ErrorView.vue"),
  meta: {
    title: "Не удалось решить ошибку",
    layout: BlankLayout,
  },
};

export const catchRoute: RouteRecordRaw = {
  path: "/:catchAll(.*)",
  name: RouteName.CATCH_ALL,
  strict: true,
  component: () => import("../views/ErrorView.vue"),
  meta: {
    title: "Не удалось найти страницу",
    layout: BlankLayout,
  },
};
