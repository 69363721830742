import { usePopovers } from "@/composables/popovers";
import { StateSubject, useApp } from "@/composables/useApp";
import { ModalName } from "@/models/app/base/appModal";
import { PopoverName } from "@/models/app/base/appPopover";
import { get } from "@vueuse/core";
import { reactive, readonly, ref } from "vue";

const shown = ref<Array<ModalName>>([]);

const findIndex = (name: ModalName): number => get(shown).indexOf(name);

const isShown = (name: ModalName): boolean => get(shown).includes(name);

const show = (name: ModalName): void => {
  if (isShown(name)) {
    return;
  }

  get(shown).push(name);
  useApp().setState(StateSubject.MODAL, name);
};

const hide = (name: ModalName): void => {
  if (!isShown(name)) {
    return;
  }

  get(shown).splice(findIndex(name), 1);
  useApp().setState(StateSubject.MODAL, name, true);
};

const toggle = (name: ModalName): void => (isShown(name) ? hide(name) : show(name));

const flip = (from: ModalName, to: ModalName | PopoverName): void => {
  hide(from);

  if (Object.values(ModalName).includes(<ModalName>to)) {
    return show(<ModalName>to);
  }

  usePopovers().show(<PopoverName>to);
};

export const useModals = () =>
  reactive({
    shown: readonly(shown),
    show,
    hide,
    toggle,
    flip,
  });
