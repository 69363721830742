<script lang="ts" setup>
import AppIcon from "@/components/base/AppIcon.vue";
import AppButtonWave from "@/components/base/button/AppButtonWave.vue";
import type { ButtonProperties, ButtonWave } from "@/models/app/base/appButton";
import { buttonDefaults } from "@/models/app/base/appButton";
import { Icon } from "@/models/app/base/appIcon";
import { get } from "@vueuse/core";
import { type Component, ref, type VNode } from "vue";

withDefaults(defineProps<ButtonProperties>(), buttonDefaults);

const waves = ref<Array<ButtonWave>>([]);

const isIcon = (extension: Icon | VNode): boolean => typeof extension === "string";

const makeWave = (event: MouseEvent): void =>
  void get(waves).push({
    position: {
      x: event.clientX - (event.target as HTMLButtonElement).offsetLeft,
      y: event.clientY - (event.target as HTMLButtonElement).offsetTop,
    },
  });

const releaseWave = (): void => void (get(waves)[get(waves).length - 1].isReleased = true);
</script>

<template>
  <button
    :class="[
      'app-button',
      `app-button--layout-${layout}`,
      `app-button--theme-${theme}`,
      `app-button--mode-${mode}`,
      `app-button--size-${size}`,
      `app-button--radius-${radius}`,
    ]"
    :disabled="isDisabled"
    @mousedown="makeWave"
    @mouseleave="releaseWave"
    @mouseup="releaseWave"
  >
    <!-- волны -->
    <AppButtonWave
      v-for="wave in waves"
      :key="`wave-${wave.position.x}-${wave.position.y}`"
      :model="wave"
    />
    <!-- префикс: слот -->
    <slot name="leading" />
    <!-- префикс: нода -->
    <Component
      :is="leading as Component"
      v-if="leading && !isIcon(leading)"
    />
    <!-- префикс: иконка -->
    <AppIcon
      v-if="leading && isIcon(leading)"
      :name="leading as Icon"
    />
    <!-- слот -->
    <slot />
    <!-- суффикс: иконка -->
    <AppIcon
      v-if="trailing && isIcon(trailing)"
      :name="trailing as Icon"
    />
    <!-- суффикс: нода -->
    <Component
      :is="trailing as Component"
      v-if="trailing && !isIcon(trailing)"
    />
    <!-- суффикс: слот -->
    <slot name="trailing" />
  </button>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/palette";

.app-button {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition:
    color animation.$speed-x-long animation.$mode-bezier-out,
    background-color animation.$speed-x-long animation.$mode-bezier-out,
    border-color animation.$speed-x-long animation.$mode-bezier-out;
  white-space: nowrap;
  column-gap: decor.$gap-x5;

  /* layouts */

  &--layout-regular,
  &--layout-flat,
  &--layout-ghost,
  &--layout-text {
    border: none;
  }

  &--layout-text {
    font-weight: fonts.$weight-bold;
    border-bottom: decor.$border-width-small dotted;

    &:hover {
      border-bottom: decor.$border-width-small solid;
    }
  }

  /* themes: regular */

  &--layout-regular#{&}--theme-regular,
  &--layout-outline#{&}--theme-regular {
    color: palette.$on-regular;

    &:hover {
      color: palette.$on-regular-light;
    }

    &:active {
      color: palette.$on-regular-dark;
    }
  }

  &--layout-flat#{&}--theme-regular,
  &--layout-ghost#{&}--theme-regular,
  &--layout-text#{&}--theme-regular {
    color: palette.$on-regular-accent;

    &:hover {
      color: palette.$on-regular-accent-light;
    }

    &:active {
      color: palette.$on-regular-accent-dark;
    }
  }

  &--layout-regular#{&}--theme-regular {
    background-color: palette.$regular;

    &:hover {
      background-color: palette.$regular-light;
    }

    &:active {
      //background-color: palette.$regular-dark;
    }
  }

  &--layout-outline#{&}--theme-regular,
  &--layout-ghost#{&}--theme-regular,
  &--layout-text#{&}--theme-regular {
    background-color: transparent;
  }

  &--layout-flat#{&}--theme-regular {
    background-color: palette.$regular-accent;
  }

  &--layout-outline#{&}--theme-regular,
  &--layout-flat#{&}--theme-regular,
  &--layout-ghost#{&}--theme-regular {
    &:hover {
      background-color: palette.$regular-accent-light;
    }

    &:active {
      //background-color: palette.$regular-accent-dark;
    }
  }

  &--layout-outline#{&}--theme-regular {
    border: decor.$border-width-regular solid palette.$regular;

    &:hover {
      border: decor.$border-width-regular solid palette.$regular-light;
    }

    &:active {
      border: decor.$border-width-regular solid palette.$regular-dark;
    }
  }

  /* themes: primary */

  &--layout-regular#{&}--theme-primary {
    color: palette.$on-primary;

    &:hover {
      color: palette.$on-primary-light;
    }

    &:active {
      color: palette.$on-primary-dark;
    }
  }

  &--layout-outline#{&}--theme-primary,
  &--layout-flat#{&}--theme-primary,
  &--layout-ghost#{&}--theme-primary,
  &--layout-text#{&}--theme-primary {
    color: palette.$on-primary-accent;

    &:hover {
      color: palette.$on-primary-accent-light;
    }

    &:active {
      color: palette.$on-primary-accent-dark;
    }
  }

  &--layout-regular#{&}--theme-primary {
    background-color: palette.$primary;

    &:hover {
      background-color: palette.$primary-light;
    }

    &:active {
      //background-color: palette.$primary-dark;
    }
  }

  &--layout-outline#{&}--theme-primary,
  &--layout-ghost#{&}--theme-primary,
  &--layout-text#{&}--theme-primary {
    background-color: transparent;
  }

  &--layout-flat#{&}--theme-primary {
    background-color: palette.$primary-accent;
  }

  &--layout-outline#{&}--theme-primary,
  &--layout-flat#{&}--theme-primary,
  &--layout-ghost#{&}--theme-primary {
    &:hover {
      background-color: palette.$primary-accent-light;
    }

    &:active {
      //background-color: palette.$primary-accent-dark;
    }
  }

  &--layout-outline#{&}--theme-primary {
    border: decor.$border-width-regular solid palette.$primary;

    &:hover {
      border: decor.$border-width-regular solid palette.$primary-light;
    }

    &:active {
      border: decor.$border-width-regular solid palette.$primary-dark;
    }
  }

  /* themes: info */

  &--layout-regular#{&}--theme-info {
    color: palette.$on-info;

    &:hover {
      color: palette.$on-info-light;
    }

    &:active {
      color: palette.$on-info-dark;
    }
  }

  &--layout-outline#{&}--theme-info,
  &--layout-flat#{&}--theme-info,
  &--layout-ghost#{&}--theme-info,
  &--layout-text#{&}--theme-info {
    color: palette.$on-info-accent;

    &:hover {
      color: palette.$on-info-accent-light;
    }

    &:active {
      color: palette.$on-info-accent-dark;
    }
  }

  &--layout-regular#{&}--theme-info {
    background-color: palette.$info;

    &:hover {
      background-color: palette.$info-light;
    }

    &:active {
      //background-color: palette.$info-dark;
    }
  }

  &--layout-outline#{&}--theme-info,
  &--layout-ghost#{&}--theme-info,
  &--layout-text#{&}--theme-info {
    background-color: transparent;
  }

  &--layout-flat#{&}--theme-info {
    background-color: palette.$info-accent;
  }

  &--layout-outline#{&}--theme-info,
  &--layout-flat#{&}--theme-info,
  &--layout-ghost#{&}--theme-info {
    &:hover {
      background-color: palette.$info-accent-light;
    }

    &:active {
      //background-color: palette.$info-accent-dark;
    }
  }

  &--layout-outline#{&}--theme-info {
    border: decor.$border-width-regular solid palette.$info;

    &:hover {
      border: decor.$border-width-regular solid palette.$info-light;
    }

    &:active {
      border: decor.$border-width-regular solid palette.$info-dark;
    }
  }

  /* themes: success */

  &--layout-regular#{&}--theme-success {
    color: palette.$on-success;

    &:hover {
      color: palette.$on-success-light;
    }

    &:active {
      color: palette.$on-success-dark;
    }
  }

  &--layout-outline#{&}--theme-success,
  &--layout-flat#{&}--theme-success,
  &--layout-ghost#{&}--theme-success,
  &--layout-text#{&}--theme-success {
    color: palette.$on-success-accent;

    &:hover {
      color: palette.$on-success-accent-light;
    }

    &:active {
      color: palette.$on-success-accent-dark;
    }
  }

  &--layout-regular#{&}--theme-success {
    background-color: palette.$success;

    &:hover {
      background-color: palette.$success-light;
    }

    &:active {
      //background-color: palette.$success-dark;
    }
  }

  &--layout-outline#{&}--theme-success,
  &--layout-ghost#{&}--theme-success,
  &--layout-text#{&}--theme-success {
    background-color: transparent;
  }

  &--layout-flat#{&}--theme-success {
    background-color: palette.$success-accent;
  }

  &--layout-outline#{&}--theme-success,
  &--layout-flat#{&}--theme-success,
  &--layout-ghost#{&}--theme-success {
    &:hover {
      background-color: palette.$success-accent-light;
    }

    &:active {
      //background-color: palette.$success-accent-dark;
    }
  }

  &--layout-outline#{&}--theme-success {
    border: decor.$border-width-regular solid palette.$success;

    &:hover {
      border: decor.$border-width-regular solid palette.$success-light;
    }

    &:active {
      border: decor.$border-width-regular solid palette.$success-dark;
    }
  }

  /* themes: warning */

  &--layout-regular#{&}--theme-warning {
    color: palette.$on-warning;

    &:hover {
      color: palette.$on-warning-light;
    }

    &:active {
      color: palette.$on-warning-dark;
    }
  }

  &--layout-outline#{&}--theme-warning,
  &--layout-flat#{&}--theme-warning,
  &--layout-ghost#{&}--theme-warning,
  &--layout-text#{&}--theme-warning {
    color: palette.$on-warning-accent;

    &:hover {
      color: palette.$on-warning-accent-light;
    }

    &:active {
      color: palette.$on-warning-accent-dark;
    }
  }

  &--layout-regular#{&}--theme-warning {
    background-color: palette.$warning;

    &:hover {
      background-color: palette.$warning-light;
    }

    &:active {
      //background-color: palette.$warning-dark;
    }
  }

  &--layout-outline#{&}--theme-warning,
  &--layout-ghost#{&}--theme-warning,
  &--layout-text#{&}--theme-warning {
    background-color: transparent;
  }

  &--layout-flat#{&}--theme-warning {
    background-color: palette.$warning-accent;
  }

  &--layout-outline#{&}--theme-warning,
  &--layout-flat#{&}--theme-warning,
  &--layout-ghost#{&}--theme-warning {
    &:hover {
      background-color: palette.$warning-accent-light;
    }

    &:active {
      //background-color: palette.$warning-accent-dark;
    }
  }

  &--layout-outline#{&}--theme-warning {
    border: decor.$border-width-regular solid palette.$warning;

    &:hover {
      border: decor.$border-width-regular solid palette.$warning-light;
    }

    &:active {
      border: decor.$border-width-regular solid palette.$warning-dark;
    }
  }

  /* themes: danger */

  &--layout-regular#{&}--theme-danger {
    color: palette.$on-danger;

    &:hover {
      color: palette.$on-danger-light;
    }

    &:active {
      color: palette.$on-danger-dark;
    }
  }

  &--layout-outline#{&}--theme-danger,
  &--layout-flat#{&}--theme-danger,
  &--layout-ghost#{&}--theme-danger,
  &--layout-text#{&}--theme-danger {
    color: palette.$on-danger-accent;

    &:hover {
      color: palette.$on-danger-accent-light;
    }

    &:active {
      color: palette.$on-danger-accent-dark;
    }
  }

  &--layout-regular#{&}--theme-danger {
    background-color: palette.$danger;

    &:hover {
      background-color: palette.$danger-light;
    }

    &:active {
      //background-color: palette.$danger-dark;
    }
  }

  &--layout-outline#{&}--theme-danger,
  &--layout-ghost#{&}--theme-danger,
  &--layout-text#{&}--theme-danger {
    background-color: transparent;
  }

  &--layout-flat#{&}--theme-danger {
    background-color: palette.$danger-accent;
  }

  &--layout-outline#{&}--theme-danger,
  &--layout-flat#{&}--theme-danger,
  &--layout-ghost#{&}--theme-danger {
    &:hover {
      background-color: palette.$danger-accent-light;
    }

    &:active {
      //background-color: palette.$danger-accent-dark;
    }
  }

  &--layout-outline#{&}--theme-danger {
    border: decor.$border-width-regular solid palette.$danger;

    &:hover {
      border: decor.$border-width-regular solid palette.$danger-light;
    }

    &:active {
      border: decor.$border-width-regular solid palette.$danger-dark;
    }
  }

  /* modes & sizes */

  &--mode-regular {
    flex: 0 0 auto;
  }

  &--mode-equilateral {
    flex: 0 0 auto;
    @include fonts.truncate;
  }

  &--mode-wide {
    flex: 0 1 decor.$size-wide;
  }

  &--layout-regular#{&}--mode-regular#{&}--size-small,
  &--layout-regular#{&}--mode-wide#{&}--size-small,
  &--layout-outline#{&}--mode-regular#{&}--size-small,
  &--layout-outline#{&}--mode-wide#{&}--size-small,
  &--layout-flat#{&}--mode-regular#{&}--size-small,
  &--layout-flat#{&}--mode-wide#{&}--size-small,
  &--layout-ghost#{&}--mode-regular#{&}--size-small,
  &--layout-ghost#{&}--mode-wide#{&}--size-small {
    padding: 0 decor.$gap-x6;
  }

  &--layout-regular#{&}--mode-regular#{&}--size-regular,
  &--layout-regular#{&}--mode-wide#{&}--size-regular,
  &--layout-outline#{&}--mode-regular#{&}--size-regular,
  &--layout-outline#{&}--mode-wide#{&}--size-regular,
  &--layout-flat#{&}--mode-regular#{&}--size-regular,
  &--layout-flat#{&}--mode-wide#{&}--size-regular,
  &--layout-ghost#{&}--mode-regular#{&}--size-regular,
  &--layout-ghost#{&}--mode-wide#{&}--size-regular {
    padding: 0 decor.$gap-x8;
  }

  &--layout-regular#{&}--mode-regular#{&}--size-large,
  &--layout-regular#{&}--mode-wide#{&}--size-large,
  &--layout-outline#{&}--mode-regular#{&}--size-large,
  &--layout-outline#{&}--mode-wide#{&}--size-large,
  &--layout-flat#{&}--mode-regular#{&}--size-large,
  &--layout-flat#{&}--mode-wide#{&}--size-large,
  &--layout-ghost#{&}--mode-regular#{&}--size-large,
  &--layout-ghost#{&}--mode-wide#{&}--size-large {
    padding: 0 decor.$gap-x10;
  }

  &--layout-regular#{&}--mode-equilateral#{&}--size-large,
  &--layout-outline#{&}--mode-equilateral#{&}--size-large,
  &--layout-flat#{&}--mode-equilateral#{&}--size-large,
  &--layout-ghost#{&}--mode-equilateral#{&}--size-large {
    width: decor.$size-z8;
  }

  &--layout-regular#{&}--mode-equilateral#{&}--size-regular,
  &--layout-outline#{&}--mode-equilateral#{&}--size-regular,
  &--layout-flat#{&}--mode-equilateral#{&}--size-regular,
  &--layout-ghost#{&}--mode-equilateral#{&}--size-regular {
    width: decor.$size-z9;
  }

  &--layout-regular#{&}--mode-equilateral#{&}--size-large,
  &--layout-outline#{&}--mode-equilateral#{&}--size-large,
  &--layout-flat#{&}--mode-equilateral#{&}--size-large,
  &--layout-ghost#{&}--mode-equilateral#{&}--size-large {
    width: decor.$size-z10;
  }

  &--layout-regular#{&}--size-small,
  &--layout-outline#{&}--size-small,
  &--layout-flat#{&}--size-small,
  &--layout-ghost#{&}--size-small {
    height: decor.$size-z8;
  }

  &--layout-regular#{&}--size-regular,
  &--layout-outline#{&}--size-regular,
  &--layout-flat#{&}--size-regular,
  &--layout-ghost#{&}--size-regular {
    height: decor.$size-z9;
  }

  &--layout-regular#{&}--size-large,
  &--layout-outline#{&}--size-large,
  &--layout-flat#{&}--size-large,
  &--layout-ghost#{&}--size-large {
    height: decor.$size-z10;
  }

  /* radii */

  &--layout-regular#{&}--radius-regular,
  &--layout-outline#{&}--radius-regular,
  &--layout-flat#{&}--radius-regular,
  &--layout-ghost#{&}--radius-regular {
    border-radius: decor.$radius-regular;
  }

  &--layout-regular#{&}--radius-maximum,
  &--layout-outline#{&}--radius-maximum,
  &--layout-flat#{&}--radius-maximum,
  &--layout-ghost#{&}--radius-maximum {
    border-radius: decor.$radius-maximum;
  }
}
</style>
