import { useModals } from "@/composables/modals";
import { StateSubject, useApp } from "@/composables/useApp";
import { ModalName } from "@/models/app/base/appModal";
import { PopoverName } from "@/models/app/base/appPopover";
import { get } from "@vueuse/core";
import { reactive, readonly, ref } from "vue";

const shown = ref<Array<PopoverName>>([]);

const findIndex = (name: PopoverName): number => get(shown).indexOf(name);

const isShown = (name: PopoverName): boolean => get(shown).includes(name);

const show = (name: PopoverName): void => {
  if (isShown(name)) {
    return;
  }
  get(shown).push(name);
  useApp().setState(StateSubject.POPOVER, name);
};

const hide = (name: PopoverName): void => {
  if (!isShown(name)) {
    return;
  }
  get(shown).splice(findIndex(name), 1);
  useApp().setState(StateSubject.POPOVER, name, true);
};

const toggle = (name: PopoverName): void => (isShown(name) ? hide(name) : show(name));

const flip = (from: PopoverName, to: PopoverName | ModalName): void => {
  hide(from);

  if (Object.values(PopoverName).includes(<PopoverName>to)) {
    return show(<PopoverName>to);
  }

  useModals().show(<ModalName>to);
};

export const usePopovers = () =>
  reactive({
    shown: readonly(shown),
    show,
    hide,
    toggle,
    flip,
  });
