import { Icon } from "@/models/app/base/appIcon";

export type ModalProperties = {
  name: ModalName;
  icon?: Icon;
  title?: string;
  label?: string;
  size?: ModalSize;
  isWide?: boolean;
  isFree?: boolean;
};

export enum ModalName {
  HELP = "Help",
  PROJECT = "Project",
  SETTINGS = "Settings",
}

export enum ModalSize {
  SMALL = "small",
  REGULAR = "regular",
  LARGE = "large",
  X_LARGE = "x-large",
}

export const modalDefaults: Pick<ModalProperties, "size"> = { size: ModalSize.REGULAR };
