<script lang="ts" setup>
import type { IconProperties } from "@/models/app/base/appIcon";
import { iconDefaults } from "@/models/app/base/appIcon";
import { type CSSProperties, ref } from "vue";

const properties = withDefaults(defineProps<IconProperties>(), iconDefaults);

const css = ref<CSSProperties>({
  width: `${properties.size}px`,
  height: `${properties.size}px`,
  stroke: String(properties.stroke),
});
</script>

<template>
  <svg
    v-if="name"
    class="app-icon"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    v-html="name"
  ></svg>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/decor";

.app-icon {
  display: flex;
  flex: 0 0 auto;
  width: v-bind("css.width");
  height: v-bind("css.height");
  transition: stroke animation.$speed-regular animation.$mode-bezier-out;
  pointer-events: none;
  fill: none;
  stroke: currentColor;
  stroke-width: v-bind("css.stroke");
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
