export type TransitionProperties = {
  name?: TransitionName;
  mode?: TransitionMode;
  isImmediate?: boolean;
};

export enum TransitionMode {
  REGULAR = "default",
  OUT_IN = "out-in",
  IN_OUT = "in-out",
}

export enum TransitionName {
  FADE_IN_FADE_OUT = "fade-in-fade-out",
  FADE_UP_IN_FADE_OUT = "fade-up-in-fade-out",
  FADE_UP_IN_FADE_DOWN_OUT = "fade-up-in-fade-down-out",
  FADE_DOWN_IN_FADE_UP_OUT = "fade-down-in-fade-up-out",
  SCALE_IN_SCALE_OUT = "scale-in-scale-out",
}

export enum TransitionGroupName {
  FADE = "fade",
  LIST = "list",
}

export enum TransitionGroupTag {
  DIV = "div",
}

export const transitionDefaults: Pick<TransitionProperties, "mode"> = {
  mode: TransitionMode.REGULAR,
};
