enum RouteName {
  ROOT = "root",
  PROJECT = "project",

  STATISTICS = "statistics",
  STATISTICS_INCOMES = "statistics-incomes",
  STATISTICS_STOCKS = "statistics-stocks",
  STATISTICS_ORDERS = "statistics-orders",
  STATISTICS_SALES = "statistics-sales",
  STATISTICS_REALIZATIONS = "statistics-realizations",

  COMPONENTS = "components",
  COMPONENTS_BUTTONS = "components-buttons",
  COMPONENTS_ICONS = "components-icons",
  COMPONENTS_CHARTS = "components-charts",

  IDENTITIES = "identities",
  IDENTIFICATION_METHOD = "identification-method",

  ERROR = "error",
  CATCH_ALL = "catch-all",
}

export default RouteName;
