import UsersController from "@/api/controller/UsersController";
import { UserResponse } from "@/api/dto/response/user/UserResponse";
import type { WebClientResponse } from "@/plugins/webClient";
import type { UserEntity } from "@/repositories/entities/UserEntity";
import UsersRepository from "@/repositories/UsersRepository";
import * as Sentry from "@sentry/vue";
import { get, isDefined, set, whenever } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useUsersStore = defineStore("user", () => {
  const activeUser = ref<UserResponse | undefined>();

  const readActiveUser = async (): WebClientResponse<UserResponse> => {
    const response = await UsersController.readActiveUser();

    if (isDefined(response.data)) {
      set(activeUser, get(response.data).data);
      await UsersRepository.put(get(response.data).data!);
    }

    return response;
  };

  const getActiveUser = async (): Promise<UserResponse | undefined> => {
    const entities = await UsersRepository.getAll();

    if (!entities.length) {
      return;
    }

    const entity = entities.reduce(
      (previous, current): UserEntity =>
        current.lastUsed > previous.lastUsed ? current : previous,
    );

    set(activeUser, new UserResponse(entity));

    return get(activeUser);
  };

  whenever(activeUser, (activeUser: UserResponse) =>
    Sentry.setUser({
      id: get(activeUser).id,
      username: get(activeUser).username,
    }),
  );

  return { activeUser, readActiveUser, getActiveUser };
});
