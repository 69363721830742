<script lang="ts" setup>
import { useRoute } from "vue-router";

const route = useRoute();
</script>

<template>
  <Component :is="route.meta.layout">
    <RouterView />
  </Component>
</template>

<style lang="scss" scoped></style>
