<script lang="ts" setup>
import type { ScrollbarProperties } from "@/models/app/base/appScrollbar";
import {
  scrollbarDefaults,
  ScrollbarDirection,
  ScrollbarRole,
} from "@/models/app/base/appScrollbar";
import { computed } from "vue";

const properties = withDefaults(defineProps<ScrollbarProperties>(), scrollbarDefaults);

const css = computed<Record<string, string>>(() => ({
  horizontal:
    properties.direction === ScrollbarDirection.VERTICAL ? ScrollbarRole.HIDDEN : properties.role,
  vertical:
    properties.direction === ScrollbarDirection.HORIZONTAL ? ScrollbarRole.HIDDEN : properties.role,
}));
</script>

<template>
  <div class="app-scrollbar">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/palette";

.app-scrollbar {
  overflow-x: v-bind("css.horizontal");
  overflow-y: v-bind("css.vertical");
  width: decor.$size-wide;
  height: decor.$size-wide;
  overscroll-behavior: none;

  &::-webkit-scrollbar {
    width: decor.$indent-x2;
    height: decor.$indent-x2;
    border-radius: decor.$radius-max;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: decor.$radius-max;
    background-color: palette.$scrollbar-light;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: palette.$scrollbar;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: palette.$scrollbar-dark;
  }
}
</style>
