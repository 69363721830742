import { Icon } from "@/models/app/base/appIcon";

export type NotificationProperties = { model: Notification };

export type Notification = {
  id?: string;
  isShown?: boolean;
  category?: NotificationCategory;
  theme?: NotificationTheme;
  icon?: Icon;
  title?: string;
  messages?: string | ReadonlyArray<string>;
  duration?: NotificationDuration;
  actions?: NotificationAction | ReadonlyArray<NotificationAction>;
};

export enum NotificationTheme {
  REGULAR = "regular",
  PRIMARY = "primary",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
}

export enum NotificationDuration {
  UNLIMITED = 0,
  SHORT = 5,
  REGULAR = 7.5,
  LONG = 10,
}

export type NotificationAction = {
  title: string;
  callback: () => any;
};

type NotificationCategoryName = "common" | "projects";

export type NotificationCategory = {
  label: string;
  icon: Icon;
};

export const notificationCategories: Record<
  NotificationCategoryName,
  NotificationCategory
> = {
  common: {
    label: "Уведомления",
    icon: Icon.BELL,
  },
  projects: {
    label: "Проекты",
    icon: Icon.APPS,
  },
};
