export type ScrollbarProperties = {
  role?: ScrollbarRole;
  direction?: ScrollbarDirection;
};

export enum ScrollbarRole {
  REGULAR = "auto",
  STATIC = "scroll",
  HIDDEN = "hidden",
}

export enum ScrollbarDirection {
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
  BOTH = "both",
}

export const scrollbarDefaults = {
  role: ScrollbarRole.REGULAR,
  direction: ScrollbarDirection.BOTH,
};
