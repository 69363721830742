import RouteName from "@/constants/RouteName";
import { ProjectEntity } from "@/repositories/entities/ProjectEntity";
import type { UserEntity } from "@/repositories/entities/UserEntity";
import UsersRepository from "@/repositories/UsersRepository";
import { router } from "@/router";
import { type DBSchema, type IDBPDatabase, openDB } from "idb";

interface Schema extends DBSchema {
  projects: {
    key: string;
    value: ProjectEntity;
    indexes: { path: string };
  };
}

export enum ProjectsStore {
  ID = "id",
  NAME = "projects",
  INDEX_PATH = "path",
}

export default class UserDatabase {
  private static database: Promise<IDBPDatabase<Schema>> | undefined;

  protected static getDatabase = async (): Promise<IDBPDatabase<Schema>> => {
    if (this.database) {
      return this.database;
    }

    const users = await UsersRepository.getAll();

    if (!users.length) {
      await router.replace({ name: RouteName.IDENTITIES });

      throw new Error("Не удалось найти параметры для соединения с базой данных");
    }

    const user = users.reduce(
      (previous, current): UserEntity =>
        current.lastUsed > previous.lastUsed ? current : previous,
    );

    return (this.database = openDB<Schema>(user.id, 1, {
      upgrade(database, previousVersion): void {
        if (previousVersion < 1) {
          const projectsStore = database.createObjectStore(ProjectsStore.NAME, {
            keyPath: ProjectsStore.ID,
            autoIncrement: false,
          });
          projectsStore.createIndex(ProjectsStore.INDEX_PATH, ProjectsStore.INDEX_PATH, {
            unique: true,
            multiEntry: false,
          });
        }
      },
    }));
  };
}
