<script lang="ts" setup></script>

<template>
  <span class="app-hotkey">
    Alt +
    <slot />
  </span>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/md";
@use "@/assets/scss/fragments/palette";

.app-hotkey {
  font-size: fonts.$size-small;
  font-weight: fonts.$weight-x-bold;
  display: inline-flex;
  align-items: center;
  height: decor.$size-z6;
  padding: 0 decor.$gap-x4;
  white-space: nowrap;
  color: palette.$on-regular-accent-light;
  border-radius: decor.$radius-small;
  background-color: palette.$regular-accent-light;
  box-shadow: decor.$shadow-regular;

  @include md.query(md.$range-max, md.$breakpoint-tablet-large) {
    display: none;
    visibility: hidden;
  }

  &__icon-plus {
    margin-right: decor.$gap-x1;
  }
}
</style>
