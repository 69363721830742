import { defineStore } from "pinia";
import { type Ref, ref } from "vue";

type ProjectsSettings = { isDemoShown: boolean };

type SidebarSettings = { isCollapsed: boolean };

type SettingsStore = {
  project: Ref<ProjectsSettings>;
  sidebar: Ref<SidebarSettings>;
};

export const useSettingsStore = defineStore("settings", (): SettingsStore => {
  const project = ref<ProjectsSettings>({ isDemoShown: true });

  const sidebar = ref<SidebarSettings>({ isCollapsed: true });

  return { project, sidebar };
});
