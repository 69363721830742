import type { ProjectResponse } from "@/api/dto/response/project/ProjectResponse";

export class ProjectEntity {
  id: string;
  name: string;
  path: string;
  image?: string;
  lastUsed: number;

  constructor(response: ProjectResponse, lastUsed: number = +new Date()) {
    this.id = response.id;
    this.name = response.name;
    this.path = response.path;
    this.image = response.image;
    this.lastUsed = lastUsed;
  }
}
