export type AlertProperties = {
  theme?: AlertTheme;
  role?: AlertRole;
};

export enum AlertTheme {
  REGULAR = "regular",
  PRIMARY = "primary",
  SUCCESS = "success",
  INFO = "info",
  WARNING = "warning",
  DANGER = "danger",
}

export enum AlertRole {
  REGULAR = "regular",
  ROUND = "round",
  OUTLINE = "outline",
  OUTLINE_ROUND = "outline-round",
  TONAL = "tonal",
  TONAL_ROUND = "tonal-round",
  PLAIN = "plain",
  PLAIN_ROUND = "plain-round",
  TEXT = "text",
}

export const alertDefaults = {
  theme: AlertTheme.REGULAR,
  role: AlertRole.REGULAR,
};
