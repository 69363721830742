<script lang="ts" setup>
import AppIcon from "@/components/base/AppIcon.vue";
import AppTooltip from "@/components/base/dialogs/AppTooltip.vue";
import type { SidebarNavigationGroup } from "@/models/app/single/theSidebar";
import { sidebarNavigationGroups } from "@/models/app/single/theSidebar";
import { useSettingsStore } from "@/stores/settingsStore";
import { get, set } from "@vueuse/core";
import { ref } from "vue";

const settingsStore = useSettingsStore();

const navigationGroups = ref<ReadonlyArray<SidebarNavigationGroup>>(sidebarNavigationGroups);

const isTooltipShown = ref<boolean>(false);

const setTooltipShown = (isShown: boolean): void => {
  if (
    !settingsStore.sidebar.isCollapsed ||
    (get(isTooltipShown) && isShown) ||
    (!get(isTooltipShown) && !isShown)
  ) {
    return;
  }
  set(isTooltipShown, isShown);
};
</script>

<template>
  <div
    class="the-sidebar-navigation"
    @mouseleave="setTooltipShown(false)"
    @mousemove="setTooltipShown(true)"
  >
    <nav
      v-for="group in navigationGroups"
      :key="group.label"
      class="navigation-group"
    >
      <!-- заголовок -->
      <span
        v-if="group.label"
        :class="[
          'navigation-group__label',
          { 'navigation-group__label--collapsed': settingsStore.sidebar.isCollapsed },
        ]"
      >
        {{ settingsStore.sidebar.isCollapsed ? "—" : group.label }}
      </span>
      <!-- маршруты -->
      <template
        v-for="navigationItem in group.items"
        :key="navigationItem.label"
      >
        <AppTooltip :label="isTooltipShown ? navigationItem.label : ''">
          <RouterLink
            :class="[
              'navigation-item',
              { 'navigation-item--expanded': !settingsStore.sidebar.isCollapsed },
            ]"
            :to="{ name: navigationItem.routeName }"
            active-class="navigation-item--active"
          >
            <AppIcon :name="navigationItem.icon" />
            <span v-if="!settingsStore.sidebar.isCollapsed">
              {{ navigationItem.label }}
            </span>
          </RouterLink>
        </AppTooltip>
      </template>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/md";
@use "@/assets/scss/fragments/palette";

.the-sidebar-navigation {
  display: flex;
  flex-direction: column;
  height: decor.$size-wide;
  row-gap: decor.$gap-x4;
}

.navigation-group {
  display: flex;
  flex-direction: column;

  @include md.query(md.$range-max, md.$breakpoint-mobile-large) {
    flex-direction: row;
    justify-content: space-around;
  }

  &__label {
    font-size: fonts.$size-small;
    font-weight: fonts.$weight-light;
    padding: decor.$gap-x4 decor.$gap-x8;
    text-transform: uppercase;
    color: palette.$on-sidebar-light;

    &--collapsed {
      text-align: center;
    }
  }
}

.navigation-item {
  display: flex;
  align-items: center;
  height: decor.$size-z10;
  padding: 0 decor.$gap-x12;
  cursor: pointer;
  transition: animation.$speed-regular animation.$mode-bezier-out;
  transition-property: transform, color;
  column-gap: decor.$gap-x5;

  &:hover {
    font-weight: fonts.$weight-x-bold;
  }

  &:active {
    color: palette.$on-sidebar-dark;
  }

  &--active {
    font-weight: fonts.$weight-bold;
    color: palette.$on-sidebar-accent;

    &:hover {
      color: palette.$on-sidebar-accent-light;
    }
  }

  &--expanded {
    &:hover {
      transform: translateX(decor.$gap-x3);
    }
  }
}
</style>
