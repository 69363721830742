<script lang="ts" setup>
import AppTeleport from "@/components/base/AppTeleport.vue";
import { useModals } from "@/composables/modals";
import type { ModalName } from "@/models/app/base/appModal";
import { TeleportDestination } from "@/models/app/base/appTeleport";
import { get, set, watchArray } from "@vueuse/core";
import { type Component, defineAsyncComponent, shallowRef } from "vue";

type Mutable<T> = { -readonly [P in keyof T]: T[P] };

type ModalInstance = {
  name: ModalName;
  component: Component;
};

const modals = useModals();

const instances = shallowRef<ReadonlyArray<ModalInstance>>([]);

// выполняется при изменении массива: всегда минимум один added или removed
watchArray(
  modals.shown as Mutable<ReadonlyArray<ModalName>>,
  (
    _list: ReadonlyArray<ModalName>,
    _listOld: ReadonlyArray<ModalName>,
    addedModals: ReadonlyArray<ModalName>,
    removedModals: ReadonlyArray<ModalName>,
  ): void =>
    set(
      instances,
      addedModals.length
        ? [
            ...get(instances),
            ...addedModals.map(
              (name: ModalName): ModalInstance => ({
                name,
                component: defineAsyncComponent(
                  () => import(`./../../../main/dialogs/modals/Modal${name}.vue`),
                ),
              }),
            ),
          ]
        : get(instances).filter(
            (instance: ModalInstance): boolean => !removedModals.includes(instance.name),
          ),
    ),
);
</script>

<template>
  <AppTeleport :destination="TeleportDestination.BODY">
    <Component
      :is="instance.component"
      v-for="instance in instances"
      :key="instance.name"
    />
  </AppTeleport>
</template>

<style lang="scss" scoped></style>
