<script lang="ts" setup>
import AppScrollbar from "@/components/base/AppScrollbar.vue";
import AlertLayoutLevel from "@/components/main/alerts/AlertLayoutLevel.vue";
import TheModalProvider from "@/components/single/dialogs/modal/TheModalProvider.vue";
import TheSidebar from "@/components/single/sidebar/TheSidebar.vue";
import TheNavbar from "@/components/single/TheNavbar.vue";
import TheNotificationProvider from "@/components/single/TheNotificationProvider.vue";
import { useSettingsStore } from "@/stores/settingsStore";

const settingsStore = useSettingsStore();
</script>

<template>
  <div :class="['layout', { 'layout--collapsed': settingsStore.sidebar.isCollapsed }]">
    <!-- боковая панель -->
    <TheSidebar class="layout__sidebar" />
    <!-- контентная область -->
    <TheNavbar class="layout__navbar" />
    <AppScrollbar class="layout__main">
      <AlertLayoutLevel />
      <slot />
    </AppScrollbar>
    <!-- сервис -->
    <TheModalProvider />
    <TheNotificationProvider />
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/md";
@use "@/assets/scss/fragments/palette";

.layout {
  display: grid;
  height: decor.$size-wide;
  transition: grid-template-columns animation.$speed-regular animation.$mode-bezier-out;

  @include md.query(md.$range-min, md.$breakpoint-mobile-large) {
    grid-template-areas:
      "sidebar navbar"
      "sidebar main";
    grid-template-columns: decor.$size-z60 auto;
    grid-template-rows: auto 1fr;

    &--collapsed {
      grid-template-columns: decor.$size-z17 auto;
    }
  }

  @include md.query(md.$range-within, md.$breakpoint-mobile-large) {
    grid-template-areas:
      "navbar"
      "main"
      "sidebar";
    grid-template-rows: decor.$size-z17 auto decor.$size-z11;
    grid-template-columns: auto;
  }

  &__sidebar {
    grid-area: sidebar;
  }

  &__navbar {
    grid-area: navbar;
  }

  &__main {
    padding: decor.$gap-x2 decor.$gap-x8 decor.$gap-x8;
    grid-area: main;
  }
}
</style>
