import type { UserEntity } from "@/repositories/entities/UserEntity";
import { type DBSchema, openDB } from "idb";

interface Schema extends DBSchema {
  users: {
    key: string;
    value: UserEntity;
    indexes: { path: string };
  };
}

export enum UsersStore {
  ID = "id",
  NAME = "users",
}

export default class AppDatabase {
  protected static database = openDB<Schema>("app", 1, {
    upgrade(database, previousVersion): void {
      if (previousVersion < 1) {
        database.createObjectStore(UsersStore.NAME, {
          keyPath: UsersStore.ID,
          autoIncrement: false,
        });
      }
    },
  });
}
