<script lang="ts" setup>
import AppIcon from "@/components/base/AppIcon.vue";
import { Icon } from "@/models/app/base/appIcon";
import { useSettingsStore } from "@/stores/settingsStore";
import { computed } from "vue";

type SidebarToggleParameters = {
  label?: string;
  icon: Icon;
  action: () => void;
};

const settingsStore = useSettingsStore();

const parameters = computed<SidebarToggleParameters>(() => ({
  label: settingsStore.sidebar.isCollapsed ? undefined : "Свернуть",
  icon: settingsStore.sidebar.isCollapsed ? Icon.ARROW_BAR_TO_RIGHT : Icon.ARROW_BAR_TO_LEFT,
  action: () => (settingsStore.sidebar.isCollapsed = !settingsStore.sidebar.isCollapsed),
}));
</script>

<template>
  <div
    :class="[
      'the-sidebar-toggle',
      { 'the-sidebar-toggle--expanded': !settingsStore.sidebar.isCollapsed },
    ]"
    @click="parameters.action"
  >
    <AppIcon :name="parameters.icon" />
    <span>{{ parameters.label }}</span>
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/animation";
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/palette";

.the-sidebar-toggle {
  display: flex;
  align-items: center;
  padding: decor.$gap-x12;
  cursor: pointer;
  transition: animation.$speed-regular animation.$mode-bezier-out;
  transition-property: transform, color;
  color: palette.$on-sidebar-light;
  column-gap: decor.$gap-x5;
  @include fonts.truncate;

  &:hover {
    font-weight: fonts.$weight-bold;
    color: palette.$on-sidebar;
  }

  &:active {
    color: palette.$on-sidebar-dark;
  }

  &--expanded {
    &:hover {
      transform: translateX(decor.$gap-x3);
    }
  }
}
</style>
