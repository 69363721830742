import RouteName from "@/constants/RouteName";
import { Icon } from "@/models/app/base/appIcon";

export type SidebarNavigationGroup = {
  label?: string;
  items: ReadonlyArray<SidebarNavigationItem>;
};

export type SidebarNavigationItem = {
  label: string;
  routeName: RouteName;
  icon: Icon;
};

export const sidebarNavigationGroups: ReadonlyArray<SidebarNavigationGroup> = [
  {
    items: [
      {
        label: "Поставки",
        routeName: RouteName.STATISTICS_INCOMES,
        icon: Icon.BUILDING_STORE,
      },
      {
        label: "Склад",
        routeName: RouteName.STATISTICS_STOCKS,
        icon: Icon.BUILDING_WAREHOUSE,
      },
      {
        label: "Заказы",
        routeName: RouteName.STATISTICS_ORDERS,
        icon: Icon.RECEIPT_TAX,
      },
      {
        label: "Продажи",
        routeName: RouteName.STATISTICS_SALES,
        icon: Icon.SHOPPING_BAG,
      },
      {
        label: "Реализация",
        routeName: RouteName.STATISTICS_REALIZATIONS,
        icon: Icon.COINS,
      },
    ],
  },
  {
    label: "Компоненты",
    items: [
      {
        label: "Кнопки",
        routeName: RouteName.COMPONENTS_BUTTONS,
        icon: Icon.CLICK,
      },
      {
        label: "Иконки",
        routeName: RouteName.COMPONENTS_ICONS,
        icon: Icon.PHOTO,
      },
      {
        label: "Графики",
        routeName: RouteName.COMPONENTS_CHARTS,
        icon: Icon.GRAPH,
      },
    ],
  },
];
