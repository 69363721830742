import type { Notification, NotificationCategory } from "@/models/app/base/appNotification";
import {
  notificationCategories,
  NotificationDuration,
  NotificationTheme,
} from "@/models/app/base/appNotification";
import DateUtilities from "@/utilities/DateUtilities";
import StringUtilities from "@/utilities/StringUtilities";
import { get } from "@vueuse/core";
import { computed, reactive, readonly, ref } from "vue";

type Scheduler = {
  timeoutId: number;
  notificationId: string;
};

const list = ref<Array<Notification>>([]);
const schedulers = ref<Array<Scheduler>>([]);

const shown = computed<ReadonlyArray<Notification>>(() =>
  get(list).filter((notification) => notification.isShown),
);

export const useNotifications = () => {
  const find = (notification: Notification): Notification | undefined =>
    get(list).find((stored): boolean => stored.id === notification.id);

  const resetScheduler = (notification: Notification): void => {
    const schedulerIndex = get(schedulers).findIndex(
      (scheduler): boolean => scheduler.notificationId === notification.id,
    );

    if (schedulerIndex !== -1) {
      window.clearTimeout(get(schedulers)[schedulerIndex].timeoutId);
      get(schedulers).splice(schedulerIndex, 1);
    }
  };

  const hide = (notification: Notification): void => {
    const stored = find(notification);

    if (stored) {
      stored.isShown = false;
      resetScheduler(notification);
    }
  };

  const scheduleHiding = (notification: Notification): void => {
    resetScheduler(notification);

    if (notification.duration === NotificationDuration.UNLIMITED) {
      return;
    }

    const timeoutId = window.setTimeout(
      (): void => hide(notification),
      (notification.duration ?? NotificationDuration.REGULAR) * DateUtilities.msMultiplier,
    );

    get(schedulers).push({
      timeoutId: timeoutId,
      notificationId: notification.id!,
    });
  };

  const create = (notification: Notification): Notification => {
    const category: NotificationCategory = notification.category ?? notificationCategories.common;

    const storableNotification: Notification = {
      id: notification.id ?? StringUtilities.getUlid(),
      isShown: notification.isShown ?? true,
      category: category,
      theme: notification.theme ?? NotificationTheme.REGULAR,
      icon: notification.icon ?? category.icon,
      title: notification.title ?? category.label,
      messages: notification.messages,
      duration: notification.duration ?? NotificationDuration.REGULAR,
      actions: notification.actions,
    };

    get(list).push(storableNotification);
    scheduleHiding(storableNotification);

    return storableNotification;
  };

  const update = (target: Notification, update: Notification): Notification => {
    const targetIndex: number = get(list).indexOf(target);

    if (targetIndex === -1) {
      return target;
    }

    const defaults: Notification = {
      isShown: true,
      theme: NotificationTheme.REGULAR,
      duration: NotificationDuration.REGULAR,
    };

    const storableNotification: Notification = {
      ...target,
      ...defaults,
      ...update,
    };

    get(list).splice(targetIndex, 1, storableNotification);
    scheduleHiding(storableNotification);

    return storableNotification;
  };

  const show = (notification: Notification): Notification => {
    const stored = find(notification);

    if (stored) {
      return update(stored, notification);
    }

    return create(notification);
  };

  return reactive({
    list: readonly(list),
    shown: readonly(shown),
    show,
    hide,
  });
};
