import { router } from "@/router";
import {
  BrowserClient,
  BrowserTracing,
  defaultStackParser,
  makeFetchTransport,
  Replay,
} from "@sentry/browser";
import { HttpClient } from "@sentry/integrations";
import { vueRouterInstrumentation } from "@sentry/vue";

const targets = ["localhost", "https://midni.ru/api/"];

export const errorHandler = new BrowserClient({
  debug: false,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  environment: import.meta.env.VITE_ENVIRONMENT,
  release: `${import.meta.env.VITE_APP_NAME}@${import.meta.env.VITE_APP_VERSION}`,
  integrations: [
    new HttpClient({ failedRequestStatusCodes: [[400, 599]] }),
    new BrowserTracing({
      routingInstrumentation: vueRouterInstrumentation(router),
    }),
    new Replay({
      blockAllMedia: false,
      maskAllText: false,
      maskAllInputs: import.meta.env.PROD,
    }),
  ],
  tracesSampleRate: Number.parseFloat(import.meta.env.VITE_SENTRY_RATE),
  tracePropagationTargets: targets,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
