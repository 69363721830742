<script lang="ts" setup>
import AppImage from "@/components/base/AppImage.vue";
import type { AvatarProperties } from "@/models/app/base/appAvatar";
import { avatarDefaults } from "@/models/app/base/appAvatar";
import ColorUtilities from "@/utilities/ColorUtilities";
import { computed, type CSSProperties } from "vue";
import StringUtilities from "../../utilities/StringUtilities";

const properties = withDefaults(defineProps<AvatarProperties>(), avatarDefaults);

const css = computed<CSSProperties>(() => ({ background: ColorUtilities.toHex(properties.color) }));
</script>

<template>
  <div :class="['app-avatar', `app-avatar--size-${size}`]">
    <!-- аббревиатура -->
    <span
      v-if="label && !image"
      class="app-avatar__label"
    >
      {{ StringUtilities.getAbbreviation(label, 2) }}
    </span>
    <!-- изображение -->
    <AppImage
      v-if="image"
      :source="image"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/assets/scss/fragments/decor";
@use "@/assets/scss/fragments/fonts";
@use "@/assets/scss/fragments/palette";

.app-avatar {
  display: flex;
  overflow: hidden;
  flex: 0 0 auto;

  &__label {
    font-weight: fonts.$weight-x-bold;
    display: flex;
    align-items: center;
    justify-content: center;
    width: decor.$size-wide;
    height: decor.$size-wide;
    color: palette.$on-secondary;
    background-color: v-bind("css.background");
  }

  &--size-x-small {
    font-size: fonts.$size-small;
    width: decor.$size-z7;
    height: decor.$size-z7;
    border-radius: decor.$radius-small;
  }

  &--size-small {
    width: decor.$size-z8;
    height: decor.$size-z8;
    border-radius: decor.$radius-regular;
  }

  &--size-regular {
    font-size: fonts.$size-large;
    width: decor.$size-z9;
    height: decor.$size-z9;
    border-radius: decor.$radius-regular;
  }

  &--size-large {
    font-size: fonts.$size-large;
    width: decor.$size-z10;
    height: decor.$size-z10;
    border-radius: decor.$radius-regular;
  }

  &--size-x-large {
    font-size: fonts.$size-large;
    width: decor.$size-z11;
    height: decor.$size-z11;
    border-radius: decor.$radius-large;
  }
}
</style>
