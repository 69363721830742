import type { UserEntity } from "@/repositories/entities/UserEntity";

export class UserResponse {
  id: string;
  username: string;
  email: string;
  phone: string;

  constructor(entity: UserEntity) {
    this.id = entity.id;
    this.username = entity.username;
    this.email = entity.email;
    this.phone = entity.phone;
  }
}
