import type { Icon } from "@/models/app/base/appIcon";
import type { VNode } from "vue";

/* button */

export type ButtonProperties = {
  layout?: ButtonLayout;
  theme?: ButtonTheme;
  mode?: ButtonMode;
  size?: ButtonSize;
  radius?: ButtonRadius;
  type?: ButtonType;
  leading?: Icon | VNode;
  trailing?: Icon | VNode;
  isDisabled?: boolean;
};

export enum ButtonLayout {
  REGULAR = "regular",
  OUTLINE = "outline",
  FLAT = "flat",
  GHOST = "ghost",
  TEXT = "text",
}

export enum ButtonTheme {
  REGULAR = "regular",
  PRIMARY = "primary",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger",
}

export enum ButtonMode {
  REGULAR = "regular",
  EQUILATERAL = "equilateral",
  WIDE = "wide",
}

export enum ButtonSize {
  SMALL = "small",
  REGULAR = "regular",
  LARGE = "large",
}

export enum ButtonRadius {
  REGULAR = "regular",
  MAXIMUM = "maximum",
}

export enum ButtonType {
  BUTTON = "button",
  SUBMIT = "submit",
  RESET = "reset",
}

export const buttonDefaults: Pick<
  ButtonProperties,
  "layout" | "theme" | "mode" | "size" | "radius" | "type"
> = {
  layout: ButtonLayout.REGULAR,
  theme: ButtonTheme.REGULAR,
  mode: ButtonMode.REGULAR,
  size: ButtonSize.REGULAR,
  radius: ButtonRadius.REGULAR,
  type: ButtonType.BUTTON,
};

/* wave */

export type ButtonWaveProperties = { model: ButtonWave };

export type ButtonWave = {
  position: ButtonWavePosition;
  isReleased?: boolean;
};

export type ButtonWavePosition = {
  x: number;
  y: number;
};
